import nasaBuilding from "../assets/img/800px-Vehicle_Assembly_Building_at_NASA_Kennedy_Space_Center.jpeg";
import eastCoast from "../assets/img/AdobeStock_227700270-900x604.jpeg";
import ai from "../assets/img/AdobeStock_269258088-900x600.jpeg";
import windTurbine from "../assets/img/Beautiful-Panoramic-Landscape-View-of-Wind-Turbines-on-a-Windy-Hill-during-a-colorful-sunrise.-Taken-in-Washington-State-United-States-of-America.--900x604.jpg";
import hoover from "../assets/img/dc_capitalconversationsjune30_v2-900x604.jpeg";
import araki from "../assets/img/Sam-Araki-900x600.jpeg";
import aiHead from "../assets/img/artificial-intelligence.jpeg";
import opioid from "../assets/img/syntheticOpioids.jpeg";
import emoGardner from "../assets/img/emogardner.jpeg";
import scrambleForAi from "../assets/img/scrambleForAi.png";
import embraceAi from "../assets/img/embraceAi.jpeg";
import bloomberg from "../assets/img/bloomberg.png";
import ai_taliban from "../assets/img/ai_taliban.jpg";

export const inTheNewsData = [
    {
        img: ai_taliban, 
        date: "July 31, 2024", 
        description: "How America built an AI tool to predict Taliban attacks", 
        category: "News", 
        link:"/article/7", 
        external:true, 
        customImgStyle:true
    },
    {
        img: "https://i.ytimg.com/vi/Lz6Vq4snOUg/hqdefault.jpg", 
        date: "July 07, 2024", 
        description: "Founder and CEO of Rhombus Power, Dr. Anshu Roy, on CNN's First Move with Julia Chatterley", 
        category: "News", 
        link:"https://www.youtube.com/watch?v=Lz6Vq4snOUg", 
        external:true,
    },
    {
        img: bloomberg, 
        date: "June 20, 2024", 
        description: "AI Could Even the Odds in the South China Sea", 
        category: "News", 
        link:"/article/6", 
        external:true, 
        customImgStyle:true
    },
    {
        img: "https://dims.apnews.com/dims4/default/278a2cf/2147483647/strip/true/crop/1920x1278+0+0/resize/1440x959!/format/webp/quality/90/?url=https%3A%2F%2Fassets.apnews.com%2F93%2F7d%2Fa2184f9212e25e4eb8dc5049bbb8%2F8d3bcb0522364ff6a25db6d5f4e6cc2a", 
        date: "May 24, 2024", 
        description: "US intelligence agencies' embrace of generative AI is at once wary and urgent", 
        category: "News", 
        link:"https://apnews.com/article/us-intelligence-services-ai-models-9471e8c5703306eb29f6c971b6923187", 
        external:true
    },
    {
        img: embraceAi, 
        date: "May 23, 2024", 
        description: "Intelligence Embracing AI", 
        category: "News", 
        link:"https://apnews.com/article/us-intelligence-gen-ai-takeaways-8ae7d4768c8e64429061c8ba3b272d19", 
        external:true
    },
    {
        img: "https://em-graphics.s3.us-west-2.amazonaws.com/archintel-media/9602a80e-2f2c-4ce6-bdde-1c40c5100922.png", 
        date: "December 20, 2023", 
        description: "Air Force Eyes January Rollout of Platform to Assist in Investment Decision-Making", 
        category: "News", 
        link:"https://executivegov.com/2023/12/air-force-eyes-january-rollout-of-platform-to-assist-in-investment-decision-making/", 
        external:true
    },
    {
        img: "https://federalnewsnetwork.com/wp-content/uploads/2023/06/army-defense-cyber-digital-1170x600.jpg", 
        date: "December 19, 2023", 
        description: "New decision advantage tool will change how Air Force makes investment decisions", 
        category: "News", 
        link:"https://federalnewsnetwork.com/air-force/2023/12/new-decision-advantage-tool-will-change-how-air-force-makes-investment-decisions/", 
        external:true
    },
    {
        img: scrambleForAi, 
        date: "June 19, 2023", 
        description: "AI HAS ENTERED THE SITUATION ROOM", 
        category: "News", 
        link:"/article/5", 
        external:true, 
        customImgStyle:true
    },
    {
        img: emoGardner,
        date: "October 26, 2022",
        description: "Remembering Lt Gen Emerson “Emo” Gardner",
        category: "In Memoriam",
        link: "/article/4",
        external: true,
    },
    {
        img: aiHead,
        date: "June 28, 2022",
        description: "The Full AI Story | Artificial Intelligence | ASP",
        category: "News",
        link: "https://www.americansecurityproject.org/the-full-ai-story/",
        external: true
    },
    {
        img: windTurbine,
        date: "April 14, 2022",
        description: "The low carbon path is paved by AI Innovation",
        category: "News",
        link: "/article/3",
        external: true
    },
    {
        img:  nasaBuilding,
        date: "February 22, 2022",
        description: "Rhombus Power's Rama Nemani awarded NASA's highest honor",
        category: "News",
        link: "/article/2",
        external: true
    },
    {
        img: eastCoast,
        date: "February 1, 2022",
        description: "Defense News: These seven principles could help DoD acquisition in the face of the China threat",
        category: "News",
        link: "/article/1",
        external: true
    },
    {
        img: hoover,
        date: "June 30, 2021",
        description: "Talks from the Hoover Institution: The Artificial Intelligence Revolution",
        category: "News",
        link: "https://www.hoover.org/events/artificial-intelligence-revolution",
        external: true
    }, 
    {
        img: araki,
        date: "May 25, 2021",
        description: "Defense & Aerospace Report: DEFAERO Andy Marshall Strategy Series w/ Sam Araki",
        category: "News",
        link: "https://defaeroreport.com/2021/05/25/defaero-andy-marshall-strategy-series-w-sam-araki-may-25-21/",
        external: true
    },
    {
        img: "https://www.researchgate.net/publication/368754045/figure/fig1/AS:11431281122155906@1677209263830/A-depiction-of-The-Ladder-of-Causation-Republished-with-permission-from-Ref-15.png",
        date: "March, 2021",
        description: "Lessons from SABLE SPEAR: The Application of an Artificial Intelligence Methodology in the Business of Intelligence",
        category: "News",
        link: "https://www.cia.gov/resources/csi/static/ArticleSableSpearExperimentInAI.pdf",
        external: true
    },
    {
        img: ai,
        date: "January 1, 2021",
        description: "Modern War Institute: Artificial Intelligence In Counterterrorism and Counterinsurgency, With Retired Gen. Stan McChrystal and Dr. Anshu Roy",
        category: "News",
        link: "https://mwi.usma.edu/artificial-intelligence-in-counterterrorism-and-counterinsurgency-with-retired-gen-stan-mcchrystal-and-dr-anshu-roy/",
        external: true
    }, 
    {
        img: opioid, 
        date: "August 20, 2019", 
        description: "Defense One: Military Scientists Harness AI To Fight Synthetic Opioids",
        category: "News", 
        link: "https://www.defenseone.com/technology/2019/08/military-scientists-harness-ai-fight-synthetic-opioids/159331/", 
        external: true 
    }
]
