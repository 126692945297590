import nasa from "../assets/img/800px-Vehicle_Assembly_Building_at_NASA_Kennedy_Space_Center.jpeg";
import world from "../assets/img/AdobeStock_227700270-900x604.jpeg";
import windmills from "../assets/img/Beautiful-Panoramic-Landscape-View-of-Wind-Turbines-on-a-Windy-Hill-during-a-colorful-sunrise.-Taken-in-Washington-State-United-States-of-America.--900x604.jpg";
import hoover from "../assets/img/dc_capitalconversationsjune30_v2-900x604.jpeg";
import aiHead from "../assets/img/artificial-intelligence.jpeg";
import emoGardner from "../assets/img/emogardner.jpeg";
import scrambleForAi from "../assets/img/scrambleForAi.png"
import bloomberg from "../assets/img/bloomberg.png";
import ai_taliban from "../assets/img/ai_taliban.jpg";

export const articles = {
    "7": {
        title: "How America built an AI tool to predict Taliban attacks", 
        date: "July 31, 2024", 
        titleImage: ai_taliban, 
        category: 'News', 
        content: ["“In the summer of 2020 American intelligence analysts in Afghanistan got a warning from “Raven Sentry”, an artificial-intelligence tool that they had been operating for a few months. There was a high probability, the AI told them, of a violent attack in Jalalabad, the capital of the eastern Nangarhar province, at the beginning of July. It would probably cause between 20 and 40 casualties. The attack came, a little July. It would probably cause between 20 and 40 casualties. The attack came, a little late, on August 2nd, when Islamic State struck the city’s prison, killing some 29 people.", 
        "Raven Sentry had its origins in October 2019, when American forces in Afghanistan were facing a conundrum. They had ever fewer resources, with troop numbers falling, bases closing and intelligence resources being diverted to other parts of the world. Yet violence was rising. The last quarter of 2019 saw the highest level of Taliban attacks in a decade. To address the problem they turned to AI.”",
        "This article, published in The Economist, discusses how Rhombus collaborated with the US military to develop an AI tool designed to predict Taliban attacks in Afghanistan. The project— dubbed Raven Sentry— aimed to provide actionable insights and improve strategic planning for US and partner forces. The article touches on the breakthrough findings Dr. Anshu Roy, Founder/CEO of Rhombus, and the team revealed from the data. In addition to describing the overall accuracy of the models and the opportunities this technology brings, Dr. Roy also provides insight on the challenges and limitations of building such data tools.",
                    "Read the whole article on The Economist <a href='https://www.economist.com/science-and-technology/2024/07/31/how-america-built-an-ai-tool-to-predict-taliban-attacks' className=\"inContentLink\" target=\"_blank\"> here.</a>"],
        nextTitle: "AI Could Even the Odds in the South China Sea", 
        nextImage: bloomberg, 
        linkProps: {
            external: true, 
            url:"/article/6", 
            newTab:true
        }, 
        metaDescription: "Read: How America built an AI tool to predict Taliban attacks "
    },
    "6": {
        title: "AI Could Even the Odds in the South China Sea", 
        date: "June 20, 2024", 
        titleImage: bloomberg, 
        category: 'News', 
        content: ["“Ultimately, it boils down to one simple fact: China asserts ownership over almost all of the South China Sea, and dismisses the claims of other countries. With new coast guard rules put into place, it now says it has the legal right to kick any foreign vessel out of the area. ",
        "Artificial intelligence can help take away some of the unknowns in an already volatile situation. Forecasting would allow the Philippines and its allies to track Beijing’s movements, game out strategies and take protective action to try and avoid another class. As Anshu Roy, the Chief Executive Officer of Silicon Valley-based AI company Rhombus Power told me, that is already happening.”", 
        "This Bloomberg article outlines how a clash between Chinese and Filipino forces in the South China Sea demonstrates Beijing’s escalating aggression in the region, as well as the role AI can play in deterring a larger conflict. Dr. Anshu Roy, Founder/CEO of Rhombus, details how his company is already leveraging predictive AI to anticipate Chinese maritime movements and provide national security leaders decision advantage in the South China Sea. For example, Rhombus previously predicted 3,200 different military events in the region— of which 2,500 (nearly 80%!) turned out to be true.",
                    "Read the whole Bloomberg story <a href='https://www.bloomberg.com/opinion/articles/2024-06-20/china-philippines-how-to-even-the-odds-in-the-south-china-sea' className=\"inContentLink\" target=\"_blank\"> here.</a>"],
        nextTitle: "AI HAS ENTERED THE SITUATION ROOM", 
        nextImage: scrambleForAi, 
        linkProps: {
            external: true, 
            url:"/article/5", 
            newTab:true
        }, 
        metaDescription: "Read: AI Could Even the Odds in the South China Sea"
    },
    "5": {
        title: "AI HAS ENTERED THE SITUATION ROOM", 
        date: "June 19, 2023", 
        titleImage: scrambleForAi, 
        category: 'News', 
        content: ["Rhombus CEO Dr. Anshu Roy and Retired  Commander of Joint Special Operations Command (JSOC) Stanley McChrystal were invited to open a long analysis of Artificial Intelligence’s potential application and implications for foreign policy and national security decision-making.", 
                    "Read it in Foreign Policy (FP’s) inaugural AI Issue on newsstands now and online at FP <a href='https://foreignpolicy.com/2023/06/19/ai-artificial-intelligence-national-security-foreign-policy-threats-prediction/' className=\"inContentLink\" target=\"_blank\"> here.</a>"],
        nextTitle: "Remembering Lt Gen Emerson “Emo” Gardner", 
        nextImage: emoGardner, 
        linkProps: {
            external: true, 
            url:"/article/4", 
            newTab:true
        }, 
        metaDescription: "Read: Rhombus CEO Dr. Anshu Roy and General Stan McChrystal "
    },
    "4": {
        title: "Remembering Lt Gen Emerson “Emo” Gardner",
        date: "October 26, 2022",
        titleImage: emoGardner,
        category: "In Memoriam",
        subtitle: null,
        content: [
            'At Rhombus, we remember and honor a giant, Lt Gen Emerson “Emo” Gardner, who was one of our most senior and trusted advisors in these last years as we deepened our collaboration with the United States national security enterprise. “Emo” was brilliant, candid, and ever committed to the brave Americans he had led in uniform, and to the work, out of uniform as a civilian, of making sure they had the right cutting edge tools to stay ahead of our adversaries.',
            'They say that you never stop being a Marine; it’s a way of life, not a job. Emo Gardner was a  Marine’s Marine, and we were lucky to know him and learn from him. We share our condolences with his family, and with all those he inspired and motivated over a lifetime of service.'
        ],
        nextTitle: "The Full AI Story | Artificial Intelligence | ASP",
        nextImage: aiHead,
        linkProps: {
            external: true,
            url: "https://www.americansecurityproject.org/the-full-ai-story/",
            newTab: true
        },
        metaDescription:"Rhombus remembers and honors Lt Gen Emerson \"Emo\" Gardner, a senior and trusted advisor in their collaboration with the US national security enterprise."
    },
    "3": {
        title: "The low carbon path is paved by AI innovation",
        date: "April 14, 2022",
        titleImage: windmills,
        category: "News",
        subtitle: null,
        content: [
            "In November 2021, the United States and the World Economic Forum launched the First Movers Coalition: as “a new platform for companies to harness their purchasing power and supply chains to create early markets for innovative clean energy technologies that are key for tackling the climate crisis.” ",
            "On January 13, 2022, the Center for Climate and Security convened a panel to discuss potential opportunities and synergies as the State Department, Department of Defense and private industry all pursue the goal of accelerating innovation, market demand, and adoption of clean energy technologies.",
            "<iframe id=\"first-movers-video\"  title=\"The First Movers Coalition and US National Security | January 13, 2022\" src=\"https://www.youtube.com/embed/dLjrHDuTIT0?feature=oembed\"></iframe>",
            "Panelists included: Sherri Goodman, Senior Advisor, The Center for Climate & Security Varun Sivaram, Senior Director for Clean Energy & Innovation, Office of US Special Presidential Envoy for Climate John Kerry, US State Department Joe Bryan, Senior Advisor for Climate, Office of the Secretary of Defense Dr. Mike Witt, Vice President and Chief Sustainability Officer, Northrop Grumman Brian Moran, Vice President, Global Sustainability Policy & Partnerships, The Boeing Company Dr. Anshu Roy, Founder & CEO, Rhombus Power"
        ],
        nextTitle: "Rhombus Power's Rama Nemani awarded NASA's highest honor",
        nextImage: nasa,
        linkProps: {
            external: true,
            url: "/article/2",
            newTab: true
        },
        metaDescription:"The US and WEF launched the First Movers Coalition to encourage companies to create markets for innovative clean energy tech to address climate crisis."
    },
    "2": {
        title: "Rhombus Power's Rama Nemani awarded NASA's highest honor",
        date: "February 22, 2022",
        titleImage: nasa,
        category: "News",
        subTitle: "Innovator New Helping to Drive Rhombus Work on Climate Threat Recently Awarded the \"Distinguished Service Medal\"",
        content: [
            '<strong>Dr. Ramakrishna Nemani</strong>, previously a research scientist with NASA with over thirty years of experience in theory and application of optical remote sensing, numerical bio-geochemical modeling, climatology and machine learning, has been awarded NASA\'s highest honor, the \"Distinguished Service Medal\"',
            "Dr. Nemani has been a pioneer in the field of ecological forecasting and collaborative Earth science. He served as a member for several NASA missions including EOS (Earth Observing System), LDCM (Landsat Data Continuity), and NPP (NPOESS Preparatory Project). He published over 250 papers with over 45000 citations and a h index of 90. Nemani joined Rhombus Power in the Fall of 2021."
        ],
        "nextTitle": "Defense News: These seven principles could help DoD acquisition in the face of the China Threat",
        nextImage: world,
        linkProps: {
            external: true,
            url: "/article/1",
            newTab: true
        },
        metaDescription:"Innovator New Helping to Drive Rhombus Work on Climate Threat Recently Awarded the \"Distinguished Service Medal\""
    },
    "1": {
        title: "Defense News: These seven principles could help DoD acquisition in the face of the China threat",
        documentTitle:"Defense News: These seven principles could help DoD acquisition",
        date: "February 1, 2022",
        titleImage: world,
        category: "News",
        subTitle: null,
        content: [
            "By Robert A. McDonald Sr., M. Sam Araki and Robert Wilkie<br />The U.S. military is getting older faster than it is getting modern. As currently postured, “the U.S. military continues to be only marginally able to meet the demands of defending America's vital national interests.” The crisis is most evident <a className=\"inContentLink\" href=\"https://www.defensenews.com/flashpoints/2022/01/24/china-sends-39-warplanes-toward-taiwan-largest-in-new-year/\" target=\"_blank\">in the Pacific</a>.",
            "The Middle Kingdom's military is not only rapidly expanding, but it is buttressed by financial muscle of which the sclerotic Soviet Union could only dream. But China is not invincible: It has structural, ideological and geographic vulnerabilities that can be exploited by a vigorous America. But we must act now — invest in new weapons, divest legacy systems, and harden our space and cyber assets. Unleash Silicon Valley to condense the kill chain by turning to artificial intelligence. But we must also burst out of industrial-age acquisition and developmental straitjackets.",
            "As we begin 2022, there are two factors that impact national security. We believe they require urgent attention. First, there is a <a className=\"inContentLink\" href=\"https://www.defensenews.com/global/asia-pacific/2022/01/06/china-denies-us-report-its-rapidly-growing-its-nuclear-arms/\" target=\"_blank\">growing threat from China</a> in the area of technology. Second, the U.S. Air Force and U.S. Space Force are facing challenges in their acquisition processes. We believe there is a unique acquisition approach — what we call the “7 Tenets” — that can be employed to address this dilemma. But, first, we will clarify the dual challenges.",
            "Over the past two years, the national security community as well as the media have made it clear that China's development of weapons technology is on a trajectory where it is likely to exceed that of the United States. Various media sources have underscored this in their reports, pointing out that (1) China already has exceeded the U.S. in development of hypersonic technology; (2) the best proposed way to track hypersonic vehicles is via space-based sensors; and (3) China is rapidly catching up to the U.S. in space capabilities.",
            "Over the past year, the DoD has placed an urgency on reorganizing Air Force and Space Force acquisition systems. The existing acquisition processes may not be best positioned to respond to the threat. In congressional testimony last year, a senior Government Accountability Office official noted that over the past decades, the DoD has faced a variety of challenges in its space acquisitions, such as schedule delays, multibillion-dollar cost increases, significant reductions in capabilities and in some cases cancelation.",
            "In a December 2021 <a className=\"inContentLink\" href=\"https://breakingdefense.com/2021/12/for-space-force-its-acquisition-acquisition-acquisition-2022-preview/\" target=\"_blank\">Breaking Defense article</a>, journalist Theresa Hitchens wrote that the task of acquisition reform will not be easy because of the “hidebound and byzantine space acquisition bureaucracy” that currently exists.",
            "What does that mean? We see it as the “sludge” that Harvard professor Cass R. Sunstein has written about. He has explained that “sludge” is anything that slows down the process of meeting objectives; it is the unmovable and undesirable tasks and diversions associated with doing business. “Sludge” is the obstacle to DoD modernization and acquisition.",
            "How can that “sludge” be reduced or eliminated?",
            "It is not rocket science. The 7 Tenets shaped an environment of creativity during the Cold War and resulted in revolutionary changes. Eliminating the “sludge” will move toward the rapid development of innovation. In a nutshell the tenets were:",
            "<span className=\"tenet-li\">1. Clearly identify the threats and respond with specific objectives to overcome them.<br /><span><span className=\"tenet-li\">2. Establish short deadlines and work quickly.<br /></span><span className=\"tenet-li\">3. Ensure that funding and staffing are adequate and stable.<br /></span><span className=\"tenet-li\">4. Use breakaway teams that are collaborative.<br /></span><span className=\"tenet-li\">5. Employ experienced experts who have proven themselves.<br /></span><span className=\"tenet-li\">6. Use the latest technology and adapt it for your objectives.<br /></span><span className=\"tenet-li\">7. Establish a short chain of command, and avoid bureaucratic “sludge.”</span>",
            "These principles were applied to the development and operation of five Cold War-era U.S. overhead photographic spy systems that resulted in dramatic innovations in the 1950s, '60s and '70s.",
            "In the 1950s, it was the U-2, which was an advanced spy plane that could fly at 70,000 feet and acquired photo coverage of some 15% of the USSR. In August 1960, it was the Corona spy satellite, which was the first to take photographs. In the 1960s, it was the Gambit spy satellite, which could take photographs of such high quality that automobiles could be identified. In the 1970s, it was the Hexagon spy satellite that could photograph large areas of the Earth where one image could cover the distance from Cincinnati, Ohio, to Washington, D.C. In 1976, it was the Kennen spy satellite, which opened the era of digital photography by taking digital images.",
            "By applying the 7 Tenets, the intelligence community was able to develop and begin operation of these complex systems within three to 60 months. For example, the high-resolution space imaging system became operational within only 24 months.",
            "Today's environment is very different from the Cold War. Applying the 7 Tenets won't be easy, but it is essential to adapt the 7 Tenets to today's aerospace-engineering intelligence and acquisition challenges.",
            "To do so would enable the DoD, first, to eliminate what Cass Sunstein has described as “sludge,” and then to be able to meet the expectations of DoD leadership to encounter today's threat posed by China.",
            "Robert A. McDonald Sr. is emeritus director at the Center for the Study of National Reconnaissance, a retired CIA officer, and a former professor of national security and psychology at the National War College. M. Sam Araki served as president of Lockheed Martin Missiles and Space, and the engineer who pioneered the development of the Agena spacecraft, the upper-stage space vehicle that served as the stable camera platform for the first imagery satellite, code-named Corona. Robert Wilkie served as undersecretary of defense for personnel and readiness and as secretary of the Department of Veterans Affairs."
        ],
        nextTitle: "Talks from the Hoover Institution: The Artificial Intelligence Revolution",
        nextImage: hoover,
        linkProps: {
            external: true,
            url: "https://www.hoover.org/events/artificial-intelligence-revolution",
            newTab: true
        },
        metaDescription:"USA needs to invest in new weapons, divest legacy systems, and harden its space and cyber assets, and turn to artificial intelligence, using \"7 Tenets\" approach."

    }
}

export function getArticle(num) {
    return articles[num] || null;
}
